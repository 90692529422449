/* HomePage.module.css */
.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  height: 90vh;
  margin: 6vh auto;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 25px;
  box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
}

.title {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.secondTitle {
  font-weight: 300;
  font-size: 1rem;
  margin: 0.75rem 0; /* auto yerine 0 kullanıyoruz */
  text-align: left; /* center yerine left kullanıyoruz */
  width: 100%; /* Tam genişlik için eklendi */
}

.donator {
  margin-bottom: 0.2rem;
  font-weight: 300;
  font-size: 0.9rem;
  text-align: left;
  width: 100%;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo {
  height: 100px;
  width: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.input {
  background-color: transparent;
  color: white;
  padding: 0.8rem;
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center; /* Sola hizalama için eklendi */
  width: 100%; /* Tam genişlik için eklendi */
  margin-bottom: 0.2rem;
}

.checkboxContainer label {
  font-weight: 300;
  font-size: 1rem;
  cursor: pointer;
}

.checkboxContainer input[type="checkbox"] {
  margin-right: 8px;
  accent-color: #2ecc71;
  cursor: pointer;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Butonlar arasındaki boşluğu artırdık */
  margin-bottom: 1rem;
  width: 100%; /* Grup genişliğini %100 yaptık */
}

.amountButton {
  flex: 1 0 calc(33.333% - 1rem);
  padding: 1rem;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid gray;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.amountButton:hover {
  filter: brightness(80%);
}

.amountButtonSelected {
  border: 1px solid #2ecc71;
}

.amountButtonSelected:hover {
  filter: brightness(100%);
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%;
  height: 50px; /* Sabit bir yükseklik ekleyin */
  word-wrap: break-word;
}

.submitButton:hover {
  background-color: #27ae60;
}

.submitButton:disabled {
  background-color: #cccccc;
}

.submitButton.loading {
  background-color: #2ecc71; /* Yükleme sırasında da yeşil kalır */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 5s linear infinite;
}

.buttonIcon {
  margin-right: 8px;
  display: inline-flex; /* Inline-flex olarak değiştirin */
  align-items: center; /* Dikey hizalama ekleyin */
  justify-content: center; /* Yatay hizalama ekleyin */
}

.inputContainer {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.errorText {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
  width: 100%;
}

.donationSummary {
  text-align: center;
  margin-top: 2rem;
  font-weight: 300;
  font-size: 0.8rem;
}

.validInput {
  border-color: #2ecc71 !important;
}

.invalidInput {
  border-color: red !important;
}

/* HomePage.module.css */
/* ... mevcut stiller ... */

@media (max-width: 568px) {
  .homeContainer {
    padding: 2rem 1rem; /* Daha küçük ekranlar için padding azaltıldı */
    width: 80%;
    margin: 10% auto;
    justify-content: flex-start;
    height: 90vh;
  }

  .title {
    font-size: 0.9rem; /* Başlık boyutu küçültüldü */
    margin: 0;
  }

  .donator {
    font-size: 0.7rem;
  }

  .secondTitle {
    font-size: 0.7rem; /* İkinci başlık boyutu küçültüldü */
    margin-top: 0;
  }

  .input {
    font-size: 0.9rem; /* Giriş alanı font boyutu küçültüldü */
    height: 2rem;
    margin-bottom: 0.5rem;
  }

  .inputContainer {
    margin: 0;
  }

  .amountButton {
    flex: 1 0 calc(50% - 0.2rem); /* Butonlar daha geniş olacak şekilde ayarlandı */
    font-size: 0.7rem;
    padding: 0.5rem;
  }

  .buttonGroup {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.7rem;
    gap: 0.2rem; /* Butonlar arasındaki boşluğu artırdık */
    margin-bottom: 0.5rem;
    width: 100%; /* Grup genişliğini %100 yaptık */
  }

  .submitButton {
    width: 100%; /* Submit butonu tam genişlikte olacak şekilde ayarlandı */
    font-size: 0.9rem;
    margin: 0.3rem 0 0 0;
  }

  .checkboxContainer {
    scale: 0.8;
    margin-bottom: 0;
  }

  .logoContainer {
    scale: 0.8;
    margin-bottom: 1rem;
  }

  .donationSummary {
    font-size: 0.7rem;
    margin: 0.8rem 0;
  }

  .errorText {
    color: red;
    font-size: 0.6rem;
    margin: 0;
    width: 100%;
  }
}

/* ... mevcut stiller ... */
