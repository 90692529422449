/* PaymentPage.module.css */
.paymentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  height: 90vh;
  margin: 6vh auto;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 25px;
  box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
}

.title {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo {
  height: 100px;
  width: 100px;
}

.donationInfoContainer {
  margin-top: 0.5rem;
}

.donationInfo {
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.donationAmount {
  color: #2ecc71;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.inputRow .inputWrapper {
  width: 48%;
}

.input {
  background-color: transparent;
  color: white;
  padding: 0.8rem;
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  width: 100%;
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 1rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%;
  height: 50px;
  word-wrap: break-word;
}

.buttonIcon {
  margin-right: 8px;
  display: inline-flex; /* Inline-flex olarak değiştirin */
  align-items: center; /* Dikey hizalama ekleyin */
  justify-content: center; /* Yatay hizalama ekleyin */
}

.submitButton:hover {
  background-color: #27ae60;
}

.submitButton:disabled {
  background-color: #cccccc;
}

.validInput {
  border-color: #2ecc71 !important;
}

.invalidInput {
  border-color: red !important;
}

.warningArea {
  background-color: red;
  margin-top: 1rem;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  animation: background-blink 3s infinite; /* Animasyon burada tanımlandı */
}

.warningText {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  animation: none; /* Animasyonu kaldırdık */
  color: white;
}

@keyframes background-blink {
  0% {
    background-color: red;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: red;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 5s linear infinite;
}

@media (max-width: 568px) {
  .paymentContainer {
    padding: 2rem 1rem; /* Daha küçük ekranlar için padding azaltıldı */
    width: 80%;
    margin: 10% auto;
    justify-content: flex-start;
    height: 90vh;
  }

  .title {
    font-size: 0.9rem; /* Başlık boyutu küçültüldü */
    margin-bottom: 0.5rem;
  }

  .input {
    font-size: 0.9rem; /* Giriş alanı font boyutu küçültüldü */
    height: 2rem;
    margin-bottom: 0.5rem;
  }

  .submitButton {
    width: 100%; /* Submit butonu tam genişlikte olacak şekilde ayarlandı */
    font-size: 0.9rem;
    margin: 0.3rem 0 0 0;
  }

  .logoContainer {
    scale: 0.8;
  }

  .error {
    color: red;
    font-size: 0.6rem;
    margin: 0;
    width: 100%;
  }

  .form {
    margin-top: 1.5rem;
  }

  .warningArea {
    margin-top: 0.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 10px;
    animation: background-blink 3s infinite; /* Animasyon burada tanımlandı */
    line-height: 1.2;
  }

  .warningText {
    font-size: 0.7rem;
    font-weight: 400;
  }
}
