/* ThankYouPage.module.css */
.thankYouContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  height: 90vh;
  margin: 6vh auto;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 25px;
  box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.9);
}

.title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.message {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.donationInfo {
  font-size: 1.1rem;
  font-weight: bold;
  color: #2ecc71;
  margin-bottom: 1.5rem;
}

.homeLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%;
  height: 50px; /* Sabit bir yükseklik ekleyin */
  word-wrap: break-word;
  text-decoration: none;
}

.homeLink:hover {
  background-color: #27ae60;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo {
  height: 100px;
  width: 100px;
}

.logoTitle {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.buttonIcon {
  margin-right: 8px;
  display: inline-flex; /* Inline-flex olarak değiştirin */
  align-items: center; /* Dikey hizalama ekleyin */
  justify-content: center; /* Yatay hizalama ekleyin */
}

.checkmark {
  font-size: 5rem;
  color: #27ae60;
  animation: fadeIn 2s ease; /* Animasyon ekleyin */
}

/* Yeni animasyon tanımı */
@keyframes fadeIn {
  from {
    opacity: 0; /* Başlangıç opaklığı */
    transform: translateY(-20px); /* Yukarıdan gelme efekti */
  }
  to {
    opacity: 1; /* Son opaklık */
    transform: translateY(0); /* Son konum */
  }
}

.fadeIn {
  animation: fadeIn 1s ease; /* Animasyon ekleyin */
}

/* Yeni animasyon tanımı */
@keyframes fadeIn {
  from {
    opacity: 0; /* Başlangıç opaklığı */
  }
  to {
    opacity: 1; /* Son opaklık */
  }
}

@media (max-width: 568px) {
  .thankYouContainer {
    padding: 2rem 1rem; /* Daha küçük ekranlar için padding azaltıldı */
    width: 80%;
    margin: 10% auto;
    justify-content: flex-start;
    height: 90vh;
  }

  .message {
    font-size: 0.8rem; /* Başlık boyutu küçültüldü */
    margin-bottom: 0.3rem;
    text-align: center;
  }

  .homeLink {
    width: 100%; /* Submit butonu tam genişlikte olacak şekilde ayarlandı */
    font-size: 0.9rem;
    margin: 6rem 0 0 0;
  }

  .logoContainer {
    scale: 0.8;
  }

  .donationInfo {
    margin-bottom: 3rem;
    font-size: 0.9rem;
  }

  .checkmark {
    font-size: 3rem;
  }
}
